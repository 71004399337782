import { useCallback, useState } from 'react';
export interface ModalProps {
  open: () => void;
  close: () => void;
  toggle: () => void;
  isOpen: boolean;
  anchorEl: HTMLButtonElement | null;
  isOpenAnchorEl: boolean;
  openAnchorEl: (event: React.MouseEvent<HTMLButtonElement>) => void;
  closeAnchorEl: () => void;
}
export const useModal = (defaultState = false): ModalProps => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultState);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const isOpenAnchorEl = Boolean(anchorEl);
  const openAnchorEl = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget),
    []
  );
  const closeAnchorEl = useCallback(() => setAnchorEl(null), []);

  return {
    open,
    close,
    toggle,
    isOpen,
    anchorEl,
    isOpenAnchorEl,
    openAnchorEl,
    closeAnchorEl,
  };
};
