import { useCallback, useState } from 'react';

/**
 * @template K - The type of the key used to identify elements (extends string).
 * @template V - The type of the value associated with each key (optional, defaults to undefined).
 * @template RequireKey - The type of the value associated with each key (optional, defaults to undefined).
 */
type ToggleChangeCallbackType<K, V, RequireKey extends boolean = true> = RequireKey extends true
  ? (map: Map<K, V | undefined>, key: K, value?: V | undefined) => void
  : (map: Map<K, V | undefined>, key?: K, value?: V | undefined) => void;

/**
 * @template K - The type of the key used to identify elements (extends string).
 * @template V - The type of the value associated with each key (optional, defaults to undefined).
 * @template RequireKey - The type of the value associated with each key (optional, defaults to undefined).
 */
type ToggleMethodType<K, V, RequireKey extends boolean = true> = RequireKey extends true
  ? (key: K, value?: V | undefined) => void
  : (key?: K, value?: V | undefined) => void;

interface UseToggleElements<K, V> {
  isElementToggled: (key: K) => boolean;
  handleToggleElements: (key: K, value?: V) => void;
  updateToggleValue: (key: K, value?: V) => void;
  clearToggles: (key?: K) => void;
  toggledElements: Map<K, V | undefined>;
}
/**
 * A generic hook for managing the toggling state of elements, where each element is identified by a key.
 * The state is stored in a Map, allowing for flexible use of key-value pairs.
 *
 * @template K - The type of the key used to identify elements (extends string).
 * @template V - The type of the value associated with each key (optional, defaults to undefined).
 *
 * @param {Array<[K, V | undefined]>} [initialState] - An optional array of key-value pairs to initialize the toggled elements map.
 *
 * @returns {UseToggleElements<K, V>} An object containing:
 *
 * @property {function(K): boolean} isElementToggled - Checks if an element with the given key is toggled (exists in the map).
 *
 * @property {function(K, V=): void} handleToggleElements - Toggles the element with the given key. Adds or removes it from the map, optionally setting a value for it.
 *
 * @property {function(K, V=): void} updateToggleValue - Set new value to a toggle entry. If no value provided, clears entry.
 *
 * @property {function(K=): void} clearToggles - Clears the toggled elements. If a key is provided, only that specific key is cleared; otherwise, all toggles are cleared.
 *
 * @property {Map<K, V | undefined>} toggledElements - The map of currently toggled elements.
 */
export const useToggleElements = <K extends string, V = undefined>(
  initialState?: [K, V | undefined][]
): UseToggleElements<K, V> => {
  const [toggle, setToggle] = useState<Map<K, V | undefined>>(() => new Map(initialState));

  const isElementToggled = useCallback((key: K) => toggle.has(key), [toggle]);

  const useToggleChange = <R extends boolean = true>(
    cb: ToggleChangeCallbackType<K, V, R>
  ): ToggleMethodType<K, V, R> =>
    useCallback((key?: K, value?: V) => {
      setToggle((prev) => {
        const newMap = new Map(prev);
        cb(newMap, key as K, value);
        return newMap;
      });
      // passing cb to deps can cause unexpected re-renders
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const clearToggles = useToggleChange<false>((newMap, key) => {
    if (key === undefined) {
      newMap.clear();
    } else {
      newMap.delete(key);
    }
  });

  const handleToggleElements = useToggleChange((newMap, key, value) => {
    if (newMap.has(key)) {
      newMap.delete(key);
    } else {
      newMap.set(key, value);
    }
  });

  const updateToggleValue = useToggleChange((newMap, key, value) => {
    if (value === undefined) {
      newMap.delete(key);
    } else {
      newMap.set(key, value);
    }
  });

  return { isElementToggled, handleToggleElements, updateToggleValue, clearToggles, toggledElements: toggle };
};
