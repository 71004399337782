import { useCallback, useEffect, useState } from 'react';

const DEFAULT_INPUT_MIN_WIDTH_PX = 100;

/**
 * Accepts the text value of a specific input element and calculates a minimum computed width to fit its value.
 */
export const useTextInputMinWidthUpdate = (inputTextValue: string, defaultMinWidth = DEFAULT_INPUT_MIN_WIDTH_PX) => {
  const [inputElem, setInputElem] = useState<HTMLInputElement | null>(null);
  const [inputMinWidth, setInputMinWidth] = useState(0);

  const measureInputTextWidth = useCallback(
    (element: HTMLInputElement, text: string) => {
      const ctx = document.createElement('canvas').getContext('2d');

      if (!ctx) {
        return defaultMinWidth;
      }

      const computedStyle = window.getComputedStyle(element);
      ctx.font = `${computedStyle.fontWeight} ${computedStyle.fontSize} ${computedStyle.fontFamily}`;

      return ctx.measureText(text).width + 20;
    },
    [defaultMinWidth]
  );

  useEffect(() => {
    setInputMinWidth(!inputElem ? defaultMinWidth : measureInputTextWidth(inputElem, inputTextValue));
  }, [inputTextValue, defaultMinWidth, inputElem, measureInputTextWidth]);

  return { inputMinWidth, inputElem, setInputElem };
};
